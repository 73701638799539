<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      title="充值详情"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell title="订单编号" :value="state.trade_no" />
      <van-cell title="支付时间" :value="state.payment_time" />
      <van-cell title="支付方式" :value="paymentTool" />
      <van-cell
        title="充值金额"
        :value="`${new Number(state.amount * 0.01).toFixed(2)}元`"
      />
      <van-cell title="充值状态" :value="allStatus[state.status]" />
      <div class="py-3 action-button-area text-center" v-if="userCanRefund">
        <van-button type="danger" @click="applyRefund">申请退款 </van-button>
      </div>
    </div>
  </div>
</template>

<script>
// @todo 考虑支持用户自助退款
import { computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Dialog, Toast } from "vant";
import { getRechargeDetail, applyRechargeRefund } from "@/api/account.service";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      trade_no: "",
      loading: true,
    });

    const allStatus = [
      "已创建",
      "支付成功",
      "支付失败",
      "已关闭",
      "退款中",
      "部分退款",
      "已退款",
    ];

    const paymentTool = computed(() => {
      if (state.payment_tool == "wxpay") {
        return "微信支付";
      } else if (state.payment_tool == "alipay") {
        return "支付宝";
      } else if (state.payment_tool == "icbc_digitalwallet") {
        return "数字人民币(工行)";
      } else {
        return "未知";
      }
    });

    // 注意: 这里有时区的问题需解决
    // 7天内可自助退款, 超过需联系代理后台退款
    const userCanRefund = computed(() => {
      if (
        state.status != 1 ||
        Math.ceil(new Date().getTime() / 1000) - state.created_at > 7 * 86400
      ) {
        return false;
      }
      return true;
    });

    const loadPage = async (trade_no) => {
      try {
        const { result } = await getRechargeDetail(trade_no);
        for (const key in result) {
          state[key] = result[key];
        }
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const applyRefund = () => {
      Dialog.confirm({
        title: "申请退款",
        message: "申请成功后资金将按原路返回, 是否继续?",
      })
        .then(() => {
          Toast.loading({ message: "请稍后", duration: 0, forbidClick: true });
          processRefund();
        })
        .catch(() => {
          // on cancel
        });
    };

    const processRefund = async () => {
      try {
        await applyRechargeRefund(state.trade_no);
        state.status = 4;
        Toast.success("申请成功");
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { trade_no } = route.query;
      if (!trade_no) {
        Toast.fail("参数错误");
        router.back();
      } else {
        state.trade_no = trade_no;
        loadPage(trade_no);
      }
    });

    return { state, allStatus, paymentTool, userCanRefund, applyRefund };
  },
};
</script>
